// NotFound.js

import React from 'react';
import '../assets/css/home.css'

const Error = () => {
  return (
    <div className="not-found">
       <div className="space"></div>
       <div className="space"></div>
       <div className="space"></div>
       <div className="space"></div>
       <div className="space"></div>
      <h1>404 - Not Found</h1>
      <p>The page you are looking for does not exist.</p>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
};

export default Error;
