import React from "react";
import "../../../assets/css/home.css";
import "../../../assets/css/CyncTeam.css";
import { Container, Row, Col, Card } from "react-bootstrap";
import { useState } from "react";
import { Helmet } from "react-helmet";

const JustinCalender = () => {
  const [flippedId, setFlippedId] = useState(null);

  const teamMembers = [
    {
        id: 1,
        name: "Justin Reimers",
        jobTitle: "Account Executive",
        imageUrl: "/team/justin.jpg",
        description:
          "Justin joins Cync Sofware with over five years of experience in the Fintech industry. Throughout his career, Justin has traditionally sold lending solutions within the commercial, consumer, and residential divisions of financial institutions. Additionally, he has 15 years of experience as a residential mortgage lender. In his free time, he enjoys traveling, cooking, or dining out, and competitive golf in Arizona where he resides.",
      },
  ];
  const handleFlip = (id) => {
    setFlippedId(flippedId === id ? null : id);
  };

  return (
    <div>
      <Helmet>
        <title>Justin Reimers Calendar - Cync Software®</title>
        <meta
          name="description"
          content="Get to know our dedicated team driving innovation and excellence in lending solutions and technology."
        />
         <meta name="robots" content="noindex" />
      </Helmet>

      <div className="space"></div>
      <div className="space"></div>
      <div className="space"></div>

      <Container style={{height:'auto'}}>
        <Row>
          <Col lg={5}>
          <h1>Let's Start a Conversation!</h1><br/>
          <p>Ready to supercharge your lending with Cync Software?
          Schedule a meeting with Justin Reimers using the calendar. </p>
            <Row xs={1} md={2} className="g-3">
              {teamMembers.map((member) => (
                <Col key={member.id} className="teamcol">
                  <Card
                    className={`team-member ${
                      flippedId === member.id ? "flipped" : ""
                    }`}
                    onClick={() => handleFlip(member.id)}
                  >
                    <div className="member-image">
                      {flippedId !== member.id && (
                        <span
                          className="plus-sign"
                          onClick={() => handleFlip(member.id)}
                        >
                          +
                        </span>
                      )}
                      {flippedId !== member.id && (
                        <Card.Img
                          variant="top"
                          src={member.imageUrl}
                          alt={member.name}
                        />
                      )}
                    </div>
                    <Card.Body
                      className={
                        flippedId === member.id ? "back-description" : ""
                      }
                    >
                      <Card.Title>
                        {flippedId !== member.id ? member.name : null}
                      </Card.Title>
                      <Card.Text>
                        {flippedId !== member.id ? member.jobTitle : null}
                      </Card.Text>
                      {flippedId === member.id && (
                        <div className="member-description" style={{height:'410px', fontSize: '16px'}}>
                          {member.description}
                        </div>
                      )}
                    </Card.Body>
                  </Card>
                </Col>
              ))}
            </Row>
          </Col>
          <Col lg={7}>
          <iframe src="https://meetings.salesloft.com/cyncsoftware/justinreimers" height="670" width="100%"></iframe>
          </Col>
        </Row>
      </Container>

      
      <div className="space"></div>
      <div className="space"></div>
    </div>
  );
};

export default JustinCalender;
