import React from 'react';
import { Modal, Box } from '@mui/material';

const VideoModal = ({ open, handleClose, videoUrl }) => {
  return (
    <Modal
      open={open}
      onClose={handleClose}
      aria-labelledby="video-modal-title"
      aria-describedby="video-modal-description"
    >
      <Box
        sx={{
          position: 'absolute',
          top: '55%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '80vw', // 80% of viewport width
          height: '80vh', // 80% of viewport height
          maxWidth: '1000px', // Max width for larger screens
          maxHeight: '90vh', // Max height for larger screens
          p: 2,
          outline: 'none',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        {/* <button
          onClick={handleClose}
          style={{
            position: 'absolute',
            top: 10,
            right: 10,
            background: 'none',
            border: 'none',
            fontSize: '2.5rem',
            cursor: 'pointer',
            color: '#000',
            zIndex: 1, // Ensure the button is above other elements
          }}
        >
          &times;
        </button> */}
        <div
          style={{
            position: 'relative',
            paddingBottom: '56.25%', // 16:9 aspect ratio
            height: 0,
            overflow: 'hidden',
          }}
        >
          <iframe
            src={videoUrl}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
            style={{
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              height: '100%',
            }}
          ></iframe>
        </div>
      </Box>
    </Modal>
  );
};

export default VideoModal;
