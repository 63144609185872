import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/PrivacyPolicy.css";
import HrLines from "../designs/HrLines";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  return (
    <div>
       <Helmet>
        <title>Privact Policy - Cync Software®</title>
        <meta
          name="description"
          content="Our Privacy Policy details the collection, use, confidentiality, security, sharing, and disposal of personal information."
        />
      </Helmet>
      <section className="privacy-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>Privacy Policy</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="privacy-policy">
        <Row>
          <Col>
            <section>
              <h1 className="privacyheading">Summary</h1>
              <HrLines />
              <br />
              <p className="contentpara">
                Our Privacy Policy is a statement that discloses all the ways we
                gather, use, disclose, and manage our website, client’s or
                employee’s personal information. Personal information is
                considered anything that can be used to identify an individual,
                not limited to the person’s name, address, date of birth,
                marital status, contact information, ID issue, financial/credit
                information. The Privacy Policy informs our website users,
                clients, and employees:
              </p>
              <ul className="pricayul">
                <li>
                  How personal information is collected and how it will be kept
                  confidential.
                </li>
                <li>How we collect personal information.</li>
                <li>How we will use the personal information collected.</li>
                <li>
                  How they can control the personal information collected.
                </li>
                <li>How we secure the data.</li>
                <li>How we dispose of the data.</li>
                <li>Who do we share data with?</li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">Information Being Collected</h1>
              <HrLines />
              <br />
              <p  className="contentpara">
                We may collect the following categories of information from our:
              </p>
              <p className="privacysubheading">Website visitors:</p>
              <ul className="pricayul">
                <li>
                  Contact Information, such as, Name, Company Name, Company
                  website, Telephone number and Email address.
                </li>
                <li>
                  Email address, name, and contact information of visitors who
                  register for the Cync Newsletter.
                </li>
                <li>Email addresses and names in blog entries.</li>
              </ul>

              <p className="privacysubheading">Application Authorized Users:</p>
              <ul className="pricayul">
                <li>
                  Contact Information such as: Name, Company Name, Company
                  website, Telephone number, Addresses, Email Addresses, Income
                  Information, Social Security Numbers, Tax ID, credit, and
                  Financial Information.
                </li>
              </ul>

              <p className="privacysubheading">Employees:</p>
              <ul className="pricayul">
                <li>
                  Contact Information such as: Name, Telephone number,
                  Addresses, Email Addresses, Income Information, Social
                  Security Numbers, Passport, License, Financial Information.
                </li>
                <li>Annual Reviews, Payroll and Raises/Bonuses.</li>
              </ul>

              <p className="privacysubheading">Social Media visitors:</p>
              <ul className="pricayul">
                <li>
                  Contact Information, such as, Name, Company Name, Company
                  website, Telephone number and E-mail address.
                </li>
                <li>
                  E-mail address, name, and contact information of visitors who
                  follow Cync Software.
                </li>
                <li>E-mail addresses and names in social media posts.</li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">How Data is Being Collected</h1>
              <HrLines />
              <br />
              <p className="privacysubheading">Website:</p>
              <ul className="pricayul">
                <li>
                  Cookies: Cync uses cookies to make interactions with the Web
                  sites easy and meaningful. When you visit one of Cync’s Web
                  sites, Cync will send a cookie to your computer. Standing
                  alone, cookies do not personally identify you; they merely
                  recognize your Web browser, your IP address, your domain name,
                  and any referring site. Unless you choose to identify yourself
                  with Cync, such as by filling out a “Contact Me” Web form or
                  blog, you remain anonymous to us.
                </li>
              </ul>

              <p className="privacysubheading">Application:</p>
              <ul className="pricayul">
                <li>
                  Only Authorized Users have access to the online application to
                  manually enter or file upload privacy data.
                </li>
              </ul>

              <p className="privacysubheading">Employees:</p>
              <ul className="pricayul">
                <li>
                  All private data is stored in a locked filing cabinet in a
                  lock room that only the administration has access and the
                  authority to obtain and store.
                </li>
              </ul>

              <p className="privacysubheading">Social Media:</p>
              <ul className="pricayul">
                <li>
                  Cookies: Cync uses cookies to make interactions with the
                  social media easy and meaningful. When you visit one of Cync
                  Software’s or social media pages or posts, Cync will send a
                  cookie to your computer. Standing alone, cookies do not
                  personally identify you; they merely recognize your Web
                  browser, your IP address, your domain name, and any referring
                  site.
                </li>
                <li>
                  Comments & Forms: Unless you choose to identify yourself by
                  responding to a social media post or complete a form on one of
                  Cync Software’s social media channels, you remain anonymous to
                  us.
                </li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">
                How We Use the Information Collected
              </h1>
              <HrLines />
              <br />
              <p className="privacysubheading">
                Website visitors only for the following purposes:
              </p>
              <ul className="pricayul">
                <li>
                  Follow up with individuals requesting information about our
                  Services.
                </li>
                <li>
                  Keep records of sales leads generated through the website.
                </li>
                <li>Keep records of contact information provided above.</li>
                <li>For marketing purposes.</li>
              </ul>

              <p className="privacysubheading">
                Application Authorized Users only for the following purposes:
              </p>
              <ul className="pricayul">
                <li>
                  NDS Systems does not use any of the application data that the
                  authorized users are entering or storing in the application.
                </li>
              </ul>

              <p className="privacysubheading">Employees:</p>
              <ul className="pricayul">
                <li>
                  NDS only obtains private data to keep a record of the
                  employees.
                </li>
                <li>
                  Signed Documents will be kept and updated annually to meet the
                  SOC 2 requirements.
                </li>
              </ul>

              <p className="privacysubheading">
                Social Media visitors only for the following purposes: 
              </p>
              <ul className="pricayul">
                <li>
                  Follow up with individuals requesting information about our
                  Services.
                </li>
                <li>
                  Keep records of sales leads generated through the social media
                  channel.
                </li>
                <li>Keep records of contact information provided above.</li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">
                How Employees & Users Can Control Their Data
              </h1>
              <HrLines />
              <br />
              <p className="privacysubheading">Website:</p>
              <ul className="pricayul">
                <li>
                  Visitors may opt-out of receiving advertisement or promotional
                  emails from us by selecting the opt-out link located in the
                  bottom of all our electronic communications. Site visitors may
                  also email <a href="mailto:privacy@cyncsoftware.com.">privacy@cyncsoftware.com.</a> directly with a request to
                  be removed from such communications.
                </li>
                <li>
                  Social Media Features/Blogs:
                  <ul>
                    <li>
                      Our Web site includes social media features, such as
                      Facebook and Linked In. These features may collect your IP
                      address, which page you are visiting on our Web site, and
                      may set a cookie to enable the feature to function
                      properly. Social media features are either hosted by a
                      third party or hosted directly on our Web site. Your
                      interactions with these features are governed by the
                      privacy statement of the company providing it.
                    </li>
                    <li>
                      Our Web site also offers or may offer publicly accessible
                      blogs or community forums. You should be aware that any
                      information you provide in these areas may be read,
                      collected, and used by others who access them. We are not
                      responsible for any personal information you choose to
                      submit in these forums.
                    </li>
                  </ul>
                </li>

                <li>
                  Links to Third Party Sites:
                  <ul>
                    <li>
                      Our website contains links to other sites whose privacy
                      practices may differ from those at this site. If you
                      submit personal information to any of these sites, your
                      information is governed by the applicable third-party’s
                      privacy policies, and we are not responsible for it. We,
                      therefore, encourage you to carefully read the privacy
                      policy of any Web site you visit and raise questions
                      directly with these other providers.
                    </li>
                  </ul>
                </li>
              </ul>
              <p className="privacysubheading">Application:</p>
              <ul className="pricayul">
                <li>
                  Authorized Users may opt out of receiving notifications from
                  the application by contacting the lender’s administration.
                  Users may also email  <a href="mailto:privacy@cyncsoftware.com.">privacy@cyncsoftware.com.</a>  directly with
                  a request to be removed from such communications.
                </li>
              </ul>

              <p className="privacysubheading">Employees:</p>
              <ul className="pricayul">
                <li>
                  Employees can obtain their individual private information,
                  payroll information and sign documents upon request to the
                  administration.
                </li>
                <li>
                  Employees will also have access to this Privacy Policy on the
                  Common Drive.
                </li>
              </ul>

              <p className="privacysubheading">Social Media:</p>
              <ul className="pricayul">
                <li>
                  Social media visitors may opt-out of receiving advertisements
                  or promotional messages from us by selecting the opt-out link
                  located in the settings portion of each social media channel.
                  Social media visitors may also email 
                  <a href="mailto:privacy@cyncsoftware.com.">privacy@cyncsoftware.com.</a> directly with a request to be removed
                  from such communications.
                </li>
              </ul>

              <p className="privacysubheading">Social Media Features/Blogs:</p>
              <ul className="pricayul">
                <li>
                  Our Web site includes social media features for various
                  channels, such as Facebook, LinkedIn, Twitter, and more. These
                  features may collect your IP address, which page you are
                  visiting on our social website or channel, and may set a
                  cookie to enable the feature to function properly. Social
                  media features are either hosted by a third party or hosted
                  directly on our Web site. Your interactions with these
                  features are governed by the privacy statement of the company
                  providing it.
                </li>
                <li>
                  Our social media channels also offer or may offer publicly
                  accessible comments or community forums. You should be aware
                  that any information you provide in these areas may be read,
                  collected, and used by others who access them. We are not
                  responsible for any personal information you choose to submit
                  in these forums.
                </li>
              </ul>

              <p className="privacysubheading">
                Links to Third Party Sites and Social Media Platforms:
              </p>
              <ul className="pricayul">
                <li>
                  Our website contains links to other sites or platforms whose
                  privacy practices may differ from those at this site. If you
                  submit personal information to any of these sites or
                  platforms, your information is governed by the applicable
                  third-party’s privacy policies, and we are not responsible for
                  it. We, therefore, encourage you to carefully read the privacy
                  policy of any Web site or platform you visit or use and raise
                  questions directly with these other providers.
                </li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">Data Security</h1>
              <HrLines />
              <br />
              <p>
                Cync employs commercially reasonable and appropriate security
                measures to protect against the loss, misuse, and alteration of
                the personal information it processes. These safeguards help
                protect against unauthorized access, maintain data accuracy, and
                provide for the appropriate use of personal information.
                Maintaining the integrity & confidentiality of data is Cync’s
                highest responsibility. To fulfill this responsibility, Cync is
                SOC 2 certified. The SOC 2 certification verifies that we have
                policies in place covering:
              </p>
              <ul className="pricayul">
                <li>Client Implementation</li>
                <li>Data Transfer</li>
                <li>Storage of Data</li>
                <li>Destruction of Data</li>
                <li>Transit</li>
                <li>Confidential and Private Data handling</li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">Retention & Disposal of Data</h1>
              <HrLines />
              <br />
              <p>
                Cync will comply with the applicable provisions of the
                Gramm-Leach-Bliley Act, and any other applicable privacy laws,
                pertaining to the destruction of data. 
              </p>
              <ul className="pricayul">
                <li>
                  The company shall check all equipment containing storage media
                  to ensure that any sensitive data or licensed software has
                  been removed or securely overwritten prior to disposal.
                </li>
                <li>
                  Protection of sensitive electronic information in the form of
                  attachments, and acceptable use of electronic communication
                  facilities
                </li>
                <li>
                  Retention and disposal of business correspondence in
                  accordance with national and local laws and regulations
                </li>
                <li>
                  The company shall protect important records from loss,
                  destruction, and falsification, in accordance with statutory,
                  regulatory, contractual, and business requirements.
                </li>
                <li>
                  Guidelines have been established for the retention, storage,
                  handling, and disposal of records and information.
                </li>
                <li>
                  The time period for which records are to be retained shall be
                  defined. Records and information shall be protected from loss,
                  destruction, and falsification.
                </li>
              </ul>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">No Services for Minors</h1>
              <HrLines />
              <br />
              <p>
                We do not knowingly collect information from minors. To use the
                Website, you must be the age of legal majority in your place of
                residence. We will use commercially reasonable efforts to delete
                information associated with a minor as soon as we learn that a
                minor has submitted information about himself/herself to us.
              </p>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">
                Disclosure of Personal Information
              </h1>
              <HrLines />
              <br/>
              <p>
                Cync shall not sell personal information, retain, use or
                disclose personal Information for any purpose other than for the
                specific purpose of performing the services, employment
                services, or outside of the direct business relationship between
                Subscriber and Cync.
              </p>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">
                Changes to This Privacy Statement
              </h1>
              <HrLines />
              <br />
              <p>
                NDS Systems reserves the right to change this Privacy Statement
                from time to time.
              </p>
              <p>
                For website users, we will revise the “last update” date at the
                top of the privacy policy on our website. We encourage users to
                periodically review the Privacy Policy for the latest
                information on our privacy practices.
              </p>
              <p>
                For authorized application users, we will send notices according
                to the contractual obligation set forth in the Master Service
                Agreement.
              </p>
              <p>
                For employees, we will update the Privacy Policy on the Common
                Drive for employees to access anytime they are logged into the
                VPN. Employees will be requested to review the Privacy Policy
                annually.
              </p>
            </section>
            <div className="space"></div>

            <section>
              <h1 className="privacyheading">Contacting Us</h1>
              <HrLines />
              <br />
              <p>
                Questions regarding this Privacy Statement, the information
                practices of the Company’s Web sites, or authorized application
                users should be directed to Cync Privacy. Please send an email
                to <a href="mailto:privacy@cyncsoftware.com.">privacy@cyncsoftware.com.</a>.
              </p>
            </section>
            <div className="space"></div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default PrivacyPolicy;
