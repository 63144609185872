import React, { useState } from "react";
import { Tab, Nav } from "react-bootstrap";
import { Container, Row, Col } from "react-bootstrap";
import "../../assets/css/newsevents.css";
import Newsletter from "../NewsLetter";
import Button from "../Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { fabullhorn } from "@fortawesome/free-solid-svg-icons";
import { Helmet } from "react-helmet";

// const articlesData = [
//   {
//     id: 1,
//     title: "January 2024 – Insights from Around the Industry",
//     date: "January 23,2024 \u00A0 \u00A0 | \u00A0 \u00A0 Newsletter \u00A0 \u00A0 | \u00A0 \u00A0 Cync Software",
//     imageUrl: "/images/news1.png",
//     content: "Each month we provide insights from around the lending industry.",
//     buttonLabel: "See More",
//     buttonLink: "#",
//   },
//   {
//     id: 2,
//     title: "Cync Software Launches Insights Blog",
//     date: "February 14, 2024 \u00A0 \u00A0 | \u00A0 \u00A0 Newsletter \u00A0 \u00A0 | \u00A0 \u00A0 Cync Software",
//     imageUrl: "/images/news2.png",
//     content: "Cync Software is proud to announce insights, a new blog filled with thought-provoking content for lending enthusiasts.",
//     buttonLabel: "See More",
//     buttonLink: "#",
//   },
//   // Add more articles as needed
// ];

const eventsData = [
  {
    id: 1,
    title:
      "Risk Management Association’s (RMA) 2024 Chapter Leaders Conference",
    date: "June 26-28, 2024  \u00A0 \u00A0 | \u00A0 \u00A0 Embassy Suites \u00A0 \u00A0 | \u00A0 \u00A0 Nashville, Tennessee",
    imageUrl: "/images/event3.png",
    description:
      "Join Cync Software at RMA’s 2024 Chapter Leaders Conference to discuss fresh perspectives on mitigating risk through automation!",
    buttonLabel: "Read  More",
    buttonLink: "/events/rma-chapter-leadership-conference",
    websiteLabel: <span>&nbsp;&nbsp;RMA Website</span>,
    websiteLink:
      "https://www.rmahq.org/conferences/2024-chapter-leaders-conference/",
  },

  {
    id: 2,
    title: "ABA Risk & Compliance Conference",
    date: "June 11-14, 2024 \u00A0 \u00A0 | \u00A0 \u00A0 Seattle Convention Cente \u00A0 \u00A0 | \u00A0 \u00A0 Seattle, Washington",
    imageUrl: "/images/aba.png",
    description:
      "Join Cync Software at The American Bankers Association Risk & Compliance Conference! This premier event for risk and compliance professionals assembles the nation’s top experts and practitioners to offer you the latest solutions for operational challenges and increasingly interconnected and complex bank threats.",
    buttonLabel: "Read  More",
    buttonLink: "#",
    websiteLabel: "ABA  Website",
    websiteLink:
      "https://www.aba.com/training-events/conferences/risk-and-compliance-conference",
  },

  {
    id: 3,
    title: "Financial Brand Forum",
    date: "May 20-22, 2024  \u00A0 \u00A0 | \u00A0 \u00A0 Aria Hotel & Resort  \u00A0 \u00A0 | \u00A0 \u00A0 Las Vegas, Nevada ",
    imageUrl: "/images/event2.png",
    description:
      "Join Cync Software at The Financial Brand Forum featuring over 2,500 senior executives at the biggest conference on marketing, CX, data analytics, AI, and digital growth strategies in the banking world!",
    // buttonLabel: "Read  More",
    // buttonLink: "#",
    websiteLabel: "Financial Brand Forum  Website",
    websiteLink: "https://financialbrandforum.com/",
  },
  {
    id: 4,
    title: "30th Annual Factoring Conference",
    date: "May 1-3, 2024 \u00A0 \u00A0 | \u00A0 \u00A0 Fontainebleau Miami Beach \u00A0 \u00A0 | \u00A0 \u00A0 Miami Beach, Florida ",
    imageUrl: "/images/event1.png",
    description:
      "Join Cync Software at the 30th Annual Factoring Convention to see how we can automate your Factoring operations!",
    buttonLabel: "Read  More",
    buttonLink: "#",
    websiteLabel: "IFA  Website",
    websiteLink:
      "https://www.sfnet.com/home/events/event-detail/2024/11/13/default-calendar/sfnets-80th-annual-convention",
  },
  // Add more events as needed
];

const NewsEventsPage = () => {
  const [activeTab, setActiveTab] = useState("events");

  const handleTabChange = (tab) => {
    setActiveTab(tab);
  };

  return (
    <div>
      <Helmet>
        <title>News and Events - Cync Software®</title>
        <meta
          name="description"
          content="Explore cutting-edge technology trends and innovations at our upcoming industry-leading events and conferences."
        />
      </Helmet>
      <section className="news-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>Events</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Newsletter />

      <section className="tabs">
        {/* <Nav variant="tabs" defaultActiveKey="news">
          <Nav.Item>
            <Nav.Link
              eventKey="news"
              onClick={() => handleTabChange("news")}
              active={activeTab === "news"}
              className={activeTab === "news" ? "active-tab" : ""}
            ><FontAwesomeIcon icon="fa-solid fa-bullhorn" />
              News
            </Nav.Link>
          </Nav.Item>
          <Nav.Item>
            <Nav.Link
              eventKey="events"
              onClick={() => handleTabChange("events")}
              active={activeTab === "events"}
              className={activeTab === "events" ? "active-tab" : ""}
            >
              Events
            </Nav.Link>
          </Nav.Item>
        </Nav> */}
        <Tab.Content>
          {/* <Tab.Pane eventKey="news" active={activeTab === "news"}>
            <div className="articles">
              {articlesData.map((article) => (
                <div key={article.id} className="article">
                  <div className="left">
                    <img src={article.imageUrl} alt={article.title} />
                  </div>
                  <div className="right">
                    <h1>{article.title}</h1>
                    <p style={{color:'#E93740', fontWeight: 'bold'}}>{article.date}</p>
                    <p>{article.content}</p>
                    <Button size="small" color="primary">
                    <a href={article.buttonLink}>{article.buttonLabel}</a></Button>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane> */}

          <Tab.Pane eventKey="events" active={activeTab === "events"}>
            <div className="events">
              {eventsData.map((event) => (
                <div key={event.id} className="event">
                  <div className="left">
                    <img src={event.imageUrl} alt={event.title} />
                  </div>
                  <div className="right">
                    <h1>{event.title}</h1>
                    <p style={{ color: "#E93740", fontWeight: "bold" }}>
                      {event.date}
                    </p>
                    <p>{event.description}</p>
                    <Button size="small" color="primary">
                      <a
                        href={event.buttonLink}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {event.buttonLabel}
                      </a>
                    </Button>
                    <a
                      target="_blank"
                      className="externallink"
                      href={event.websiteLink}
                    >
                      {event.websiteLabel}
                    </a>
                  </div>
                </div>
              ))}
            </div>
          </Tab.Pane>
        </Tab.Content>
      </section>
    </div>
  );
};

export default NewsEventsPage;
