import React from 'react';

const HrLines = ({ text }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', gap: '10px', marginTop: '10px' }}>
    <hr style={{ width: '75px', borderColor: '#E93740', borderWidth: '3px', margin: '0' , borderRadius: "5px", opacity : "inherit",}} />
    <hr style={{ width: '15px', borderColor: '#E93740', borderWidth: '3px', margin: '0' , borderRadius: "5px", opacity : "inherit",}} />
  </div>
  );
};

export default HrLines;
