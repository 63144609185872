import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/PrivacyPolicy.css";
import HrLines from "../designs/HrLines";
import { Helmet } from "react-helmet";

const DisclosurePolicy = () => {
  return (
    <div>
      <Helmet>
        <title>Responsible Disclosure Policy - Cync Software®</title>
        <meta
          name="description"
          content="NDS Systems values security. Our Responsible Disclosure Policy encourages reporting vulnerabilities to protect our systems, applications, and client information."
        />
      </Helmet>
      <section className="privacy-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>Responsible Disclosure Policy</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="privacy-policy">
        <Row>
          <Col>
            <section>
            <h1 className="privacyheading">Summary</h1>
            <HrLines /><br/>
              <p>
              NDS Systems is committed to securing our system, application, and client information. However, there are times when vulnerabilities can escape detection. In the event that one of these potential security vulnerabilities is identified, please report it to us immediately so that we may take the appropriate measures to protect our clients. We appreciate the assistance in our security efforts.
              </p>
            </section>

            <section>
            <h1 className="privacyheading">Reporting</h1>
            <HrLines /><br/>
              <p>
              Please submit your report to <a href="mailto:responsibledisclosure@cyncsoftware.com">responsibledisclosure@cyncsoftware.com</a>. Include a detailed summary of the vulnerability, including the target, steps, tools, artifacts, time/method used during the discovery, and the necessary steps for reproducing the vulnerability. If you have any screen captures available, please include them as well. We would like to contact you about the assessment and the progress of the vulnerability solution, so please include your contact information.
              </p>
            </section>

            <section>
            <h1 className="privacyheading">Policy Guidelines</h1>
            <HrLines /><br/>
            <p>
              By responsibly submitting your findings to NDS Systems in accordance with the stated guidelines, NDS Systems agrees not to pursue legal action against you. However, NDS Systems reserves all legal rights in the event of non-compliance with these stated guidelines.
              </p>
            <ul>
                <li>Do not share information about the security vulnerabilities with a third party or disclose it publicly until NDS Systems has resolved the problem.</li>
                <li>Be responsible with the knowledge about the security problem. Do not engage in any actions beyond what is necessary to demonstrate the security problem that could potentially stop or degrade our services or assets.</li>
                <li>Do not store, share, compromise, or destroy confidential data obtained through the vulnerability in the system.</li>
                <li>Do not engage in any activity that violates any federal or state laws or regulations.</li>
            </ul>
              
            </section>

            <section>
            <h1 className="privacyheading">Rewards/Bug Bounty</h1>
            <HrLines /><br/>
              <p>
              NDS Systems does not operate a Bug Bounty program. Therefore, we will make no offer of reward or compensation in exchange for submitting potential security vulnerabilities. We do, however, thank you for your submission and appreciate your assistance in our security efforts.
              </p>
            </section>

            <section>
            <h1 className="privacyheading">Out-of-Scope Vulnerabilities</h1>
            <HrLines /><br/>
              <p>
              These vulnerabilities, we feel, are out of scope for our Responsible Disclosure Policy:
              </p>
              <ul>
                <li>Physical testing</li>
                <li>Phishing</li>
                <li>Denial of service attacks</li>
                <li>Resource exhaustion attacks</li>
              </ul>
            </section>


          </Col>
        </Row>
      </Container>

      <div className="space"></div>
    </div>
  );
};
export default DisclosurePolicy;
