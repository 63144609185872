import React from 'react';
import '../assets/css/button.css'; // Import CSS for styling

const Button = ({ children, size, color, onClick }) => {
  const buttonClasses = `button ${size} ${color}`;

  return (
    <button className={buttonClasses} onClick={onClick}>
      {children}
    </button>
  );
};

export default Button;
