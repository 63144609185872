import React from "react";
import { Container, Row, Col } from "react-bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/PrivacyPolicy.css";
import { Helmet } from "react-helmet";

const Report = () => {
  return (
    <div>
        <Helmet>
          <title>Reporting Hotline - Cync Software®</title>
          <meta
          name="description"
          content="NDS Systems' confidential hotline allows anonymous reporting of unethical behavior, ensuring safety and protection against retaliation."
        />
        </Helmet>
      <section className="privacy-sections">
        <Container className="h-100">
          <Row className="h-100 align-items-center">
            <Col lg={9}>
              <div className="content">
                <h1>Reporting Hotline</h1>
              </div>
            </Col>
          </Row>
        </Container>
      </section>

      <Container className="privacy-policy">
        <Row>
          <Col>
            <section>
              <p className="contentpara">
                NDS Systems provides through One Digital (Resourcing Edge), an
                anonymous hotline allowing employees, third parties, and
                customers to report unethical behavior and concerns in a
                confidential manner. NDS Systems takes these complaints
                seriously. This anonymous hotline will allow us to monitor the
                business from the perspective of our employees, third parties,
                and customers without them fearing repercussion.
              </p>
              <p className="contentpara">
                This anonymous hotline will be completely confidential for any
                person desiring anonymous reporting status. Our employees, third
                parties and customers should feel safe and more confident making
                a report since they will not be identified or receive any form
                of retaliation. All employees are protected under the
                Sarbanes-Oxley Act against any corporate retaliation due to an
                employee’s involvement in a securities or fraud-related
                investigation involving the corporation. The hotline is
                available 24 hours a day, seven days a week by calling{" "}
                <a href="tel:1-885-732-4060" rel="noopener noreferrer">1-885-732-4060</a>.
              </p>
            </section>
          </Col>
        </Row>
      </Container>
      <div className="space"></div>
    </div>
  );
};
export default Report;
