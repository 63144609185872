import React from 'react';
import '../assets/css/ScheduleDemo.css';
import Button from './Button'; // Import CSS file for styling
import HrLines from './designs/HrLines';
import { Link } from "react-router-dom";
import { isValidPath } from '../utils/validation';

const handleNavigation = (url) => {
  if (isValidPath(url)) {
  window.location.assign(url);

  } else {
    console.warn('Invalid path:', url);
  }
};

const ScheduleDemo = () => {
  return (
    <section className="demo-section">
      <div className="demo-content">
      
        <h1>Supercharge Your Lending Automation</h1>
        <HrLines />
        <div className="space"></div>
        <a onClick={() => handleNavigation('/request-a-demo')} to="/request-a-demo">
        <Button size="small" color="primary">Schedule a Demo</Button></a>
      </div>
    </section>
  );
}

export default ScheduleDemo;
