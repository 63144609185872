const steps = [
  {
    label: "High Speed-To-Market with No-Code Self-Service Administration",
    description: `Want to launch a new loan product to expand your market? Want to modify your processes to lend faster or to accommodate regulatory changes? With Cync LOS’s codeless template creation, reflect business and policy decisions in your loan origination process for any kind of loan yourself without having to engage professional services.`,
    image: "/images/los-1.png",
  },
  {
    label: "Customize Your Solution with Microservices Library",
    description:
      "Build a bespoke solution that meets your unique business needs by choosing relevant microservices from Cync LOS’s extensive microservices library. Optimize your loan origination process for agility and efficiency while paying only for the features and functionality you require.",
    image: "/images/los-2.png",
  },
  {
    label: "Built for Scale and Innovation",
    description: `Scale your lending on-demand with guaranteed high availability through a cloud-based deployment, while also eliminating the need for upfront capital expenditure. Embrace open banking with Cync LOS’s API-first approach to seamlessly integrate with your tech ecosystem and consume off-the-shelf third-party APIs to drive innovation.`,
    image: "/images/los-3.png",
  },
  {
    label: "Improve Staff Productivity with Automation",
    description: `Take the focus off mundane, repetitive tasks by allowing Cync LOS’s automation to take over. Stop spending hours and instead have top-notch artificial intelligence rapidly churn out GAAP-compliant spreads for analysis. Or automatically track relationship- and loan-specific covenants and ticklers, allowing loan officers and compliance analysts to focus solely on verifying covenant evaluations.`,
    image: "/images/los-4.png",
  },
  {
    label: "Custom Dashboards with Real-Time Analytics",
    description: `Put your team first by customizing dashboard layouts for every role – credit analysts, portfolio managers, or anyone else! With a choice of over four dozen data visualizations and reports, look at data from every possible angle. Provide actionable insights in real time to enable proactive decision-making via delightfully simple user interfaces.`,
    image: "/images/los-5.png",
  },
];

const ablsteps = [
  {
    label: "Automate Borrowing Base Certificate Generation",
    description: `Stop spending hours manually sifting through hundreds of pages of documentation. Instead, just upload or import data via APIs and let Cync ABL evaluate the collateral and compute the borrowing base automatically. Cync ABL’s flexibility enables either the lender or the borrowers to generate the Borrowing Base Certificate according to the loan agreement.`,
    image: "/images/abl-1.png",
  },
  {
    label: "Define Ineligibles to Process Collateral Files",
    description:
      "Define sub-limits at borrower-level, by collateral type, or configure them based on the loan agreement. Offer different interest rates according to collateral type while consolidating under a single accounts receivable line. Establish Seasonal Advance Rates for borrowers with a peak-season. Take Aging, Contra, Global Assignments into consideration in your ineligibles calculations. In short, do everything that you do manually today quickly and accurately via Cync ABL. ",
    image: "/images/abl-2.png",
  },
  {
    label: "Monitor Every Kind of Collateral, Including Marketable Securities",
    description: `Lend with confidence to borrowers with diverse set of collateral including account receivables, inventory, real estate, equipment, government contracts (GovCon) or agricultural assets. Additionally, with Cync ABL, you can monitor and evaluate marketable securities, pulling in the value in real-time to mitigate risk.`,
    image: "/images/abl-3.png",
  },
  {
    label: "Instantly Identify Affiliated Entities",
    description: `Identify parent companies, subsidiaries, and related parties upon file upload. Gain insights into customer concentration to comprehensively assess potential areas of risk for both borrowers and the lender. Accurately evaluate collateral by identifying contras within your accounts receivable and accounts payable. `,
    image: "/images/abl-4.png",
  },
];

const factoringsteps = [
  {
    label: "Rule-Based Automatic Invoice Validation",
    description: `Stop validating each invoice manually. Set seller or debtor-specific rules to automatically validate invoices, with manual override options, to improve productivity and expedite funding.`,
    image: "/images/fact-1.png",
  },
  {
    label: "Lower Risks Through Rapid Electronic Verification",
    description:
      "Enable debtors to swiftly confirm invoice details and payment terms electronically, eliminating the need for lengthy phone calls or paper-based verification. Additionally, facilitate e-signing of documents to legally bind debtors to settle payments when invoices are due. ",
    image: "/images/fact-2.png",
  },
  {
    label: "Streamlined Collections Through Portal and Ticklers",
    description: `Empower collection agents with a one-stop shop that provides the entire invoice history, including funding approvals, comments during disbursement, and debtor contact information. Additionally, enable them to effortlessly schedule and manage follow-up actions through collection ticklers. `,
    image: "/images/fact-3.png",
  },
  {
    label: "Flexibility to Configure Fees and Charges",
    description: `Want to implement a tier-based, interest-based, or combined fee calculation method? Cync Factoring allows you to easily configure the factoring fee structure that matches your business requirements. Moreover, you can define custom charge templates to apply fees at specific intervals or in response to events like wire transfers or invoice processing.`,
    image: "/images/fact-4.png",
  },
];

const spreadingsteps = [
  {
    label: "Improve Staff Productivity with Automation",
    description: `Take the focus off mundane, repetitive tasks by allowing Cync Spreading’s automation to take over. Stop spending hours and instead have top-notch artificial intelligence rapidly churn out GAAP-compliant spreads for analysis. Or automatically track relationship-specific covenants and ticklers, allowing loan officers and analysts to focus solely on verifying covenant evaluations.`,
    image: "/images/spreading-1.png",
  },
  {
    label: "Speedy Implementation with Customizable Spreading Templates",
    description:
      "Quickly establish the foundation for importing statements from commercial, real estate, or individual clients by utilizing the built-in master spreading templates. Alternatively, modify the master templates by linking various financial statement types to design customized templates based on the category of spread, client, industry, or other criteria.  ",
    image: "/images/spreading-2.png",
  },
];


const syndicatesteps = [
  {
    label: "Effortlessly Manage Complex Syndicated and Participation Loans",
    description: `No matter the complexity of your loan terms, manage diverse structures and requirements automatically with Cync Syndicated Lending. Effortlessly handle both syndicated and participation loans. Additionally, assign different interest rates, fees, maturities, or covenants to each participant allocation, addressing specific financing needs and varied risk appetites.`,
    image: "/images/syndicate1.jpg",
  },
  {
    label: "Provide Multiple Allocation Methods and Flexible Drawdown Options",
    description:
      "Prioritize participant allocations through a percentage-based method or opt for a flexible, responsive distribution using a dynamic continuous priority system. Additionally, empower borrowers to manage their cash flows effectively by offering full drawdowns, scheduled drawdowns, bridge drawdowns, revolving credit facilities, or discretionary drawdowns tied to specific events or milestones.",
    image: "/images/syndicate2.jpg",
  },
  {
    label: "Streamline Participant Restructuring",
    description:
      "Enhance the viability and risk management of syndicated loans by automating lender composition and loan term adjustments, eliminating the need for spreadsheets. Efficiently handle changes when lenders reduce their exposure, exit, or new lenders join the syndicate. Facilitate seamless loan term adjustments, including interest rates, repayment schedules, and covenants, to accommodate changes in the syndicate.",
    image: "/images/syndicate3.png",
  },
  {
    label: "Improve Productivity With Advanced Automation",
    description:
      "Leverage automation to streamline fund allocations and repayments, ensure compliance with covenants, and conduct real-time risk assessments. Seamlessly manage drawdowns and loan modifications. Automate document management with efficient metadata tagging. Additionally, enhance participant communication through automated updates and notifications.",
    image: "/images/syndicate4.png",
  },
  {
    label: "Monitor Every Kind of Collateral, Including Marketable Securities",
    description:
      "Evaluate a wide range of collateral, including accounts receivable, inventory, real estate, equipment, government contracts (GovCon), and agricultural assets. Employ automation to expedite Borrowing Base Certificate (BBC) generation. Additionally, communicate collateral valuations to each participant, proportionate to their participation ratio, to ensure transparency.",
    image: "/images/abl-3.png",
  },
];

export { steps, ablsteps, factoringsteps, spreadingsteps, syndicatesteps };
