import React, { useState, useEffect } from 'react';
import './AlertPopup.css'; // Import a separate CSS file for styling

const AlertPopup = () => {
    const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    const timer = setTimeout(() => {
      console.log('Popup is now visible'); // Debug log
      setIsVisible(true);
    }, 3000); // Show popup after 3 seconds

    return () => clearTimeout(timer);
  }, []);

  const handleClose = () => {
    console.log('Popup closed'); // Debug log
    setIsVisible(false);
  };

  return (
    isVisible && (
      <div className="product-popup fade-in">
        <div className="popup-content">
          <span className="close-btn" onClick={handleClose}>
            &times;
          </span>
          <h4>New Product Available!</h4>
          <p>Check out our latest product. It’s awesome!</p>
        </div>
      </div>
    )
  );
};

export default AlertPopup;
