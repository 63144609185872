// ThankYouPage.js
import React, { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useNavigate } from "react-router-dom";

const ThankYouDemo = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const timer = setTimeout(() => {
      navigate("/");
    }, 3000);

    return () => clearTimeout(timer); // Cleanup the timer on component unmount
  }, [navigate]);

  return (
    <div>
      <Container>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
        <div style={{ textAlign: "center" }}>
          <h1>Thank You!</h1>
          <p>
            Thanks for submitting your details. One of our team members will
            contact you shortly
          </p>
        </div>
        <div className="space"></div>
        <div className="space"></div>
        <div className="space"></div>
      </Container>
    </div>
  );
};

export default ThankYouDemo;
