import React, { useEffect, useState } from "react";
import "./CookieConsent.css";

const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);
  const [showCloseButton, setShowCloseButton] = useState(true);

  useEffect(() => {
    const consent = getCookie("cookieConsent");
    if (!consent) {
      setTimeout(() => {
        setIsVisible(true);
      }, 2000); // Show after 2 seconds
    }
  }, []);

  const handleAgree = () => {
    setCookie("cookieConsent", "true", 365);
    setIsVisible(false); // Close the consent banner after agreeing
  };

  const handleDisagree = () => {
    setCookie("cookieConsent", "false", 365);
    setIsVisible(false); // Close the consent banner after disagreeing
  };

  const handleClose = () => {
    setIsVisible(false);
  };

  if (!isVisible) return null;

  return (
    <div className={`cookie-consent ${isVisible ? "slide-up" : ""}`}>
      <p>
        By using the website, you agree to our use of cookies to analyze website
        traffic and improve your experience.{" "}
        <a href="https://cyncsoftware.com/privacy-policy">Learn more</a>
      </p>
      <div className="buttons">
        <button className="agree" onClick={handleAgree}>
          Agree
        </button>
        <button className="disagree" onClick={handleDisagree}>
          Disagree
        </button>
      </div>
      {showCloseButton && (
        <button className="close" onClick={handleClose}>
          ✖
        </button>
      )}
    </div>
  );
};

// Helper functions to handle cookies
const setCookie = (name, value, days) => {
  const d = new Date();
  d.setTime(d.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${d.toUTCString()}`;
  document.cookie = `${name}=${value};${expires};path=/`;
};

const getCookie = (name) => {
  const nameEQ = `${name}=`;
  const ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) === " ") c = c.substring(1, c.length);
    if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
};

export default CookieConsent;
